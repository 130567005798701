// Override default variables before the import
$body-bg: #fff;
$enable-rounded: false;
$theme-colors: (
  "primary": #10306c,
  "danger": #ff4136,
  "success": #198754,
  "secondary": #6c757d,
  "light": #f8f9fa,
  "dark": #343a40,
  "warning": rgb(255, 193, 7)
);

$btn-background: #b9d9ff;
$btn-border: #fff;
$btn-color: #10306c;
$btn-padding-sides: 1.1rem;
$btn-background-basic: #10306c;
$btn-border-basic: #10306c;
$btn-color-basic: #fff;
$h3-color: #000;
$font-primary: "Montserrat", sans-serif;

$light-blue: #A5C0FA;
$lighter-blue: #CEDFFF;
$dark-purple: #110C4A;
$light-purple: #4C4690;
$dark-blue: #10306C;
$darker-gray: #D8D8D8;
$light-gray: #F8F8F8;
$green: #28a745;

$header-height-mobile: 66px;
$header-height-desktop: 130px;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px
);

:root {
  --header-height-mobile: #{$header-height-mobile};
  --header-height-desktop: #{$header-height-desktop};
  --top-menu-height: var(--header-height-mobile);

  @media (min-width: map-get($grid-breakpoints, "md")) {
    --top-menu-height: var(--header-height-desktop);
  }
}

@mixin button-variant($background, $border, $color, $padding) {
  background-color: $btn-background;
  border: 1px solid $btn-border;
  color: $btn-color;
  font-weight: bold;
  margin-left: 1rem;
  padding: 0.5rem;
  padding-right: $btn-padding-sides;
  padding-left: $btn-padding-sides;

  &:hover {
    border: 1px solid $btn-background;
    color: #fff;
  }
}

.btn-primary {
  &.cta {
    @include button-variant($btn-background,
      $btn-border,
      $btn-color,
      $btn-padding-sides );
  }
}

@mixin button-variant-basic($background, $border, $color, $padding) {
  background-color: $btn-background-basic;
  border: 1px solid $btn-border-basic;
  color: $btn-color-basic;
  font-weight: bold;
  padding: 0.5rem;
  padding-right: $btn-padding-sides;
  padding-left: $btn-padding-sides;

  &:hover {
    border: 1px solid #fff;
    color: #10306c;
    background-color: #b9d9ff;
  }
}

.btn-primary {
  &.basic {
    @include button-variant-basic($btn-background,
      $btn-border,
      $btn-color,
      $btn-padding-sides );
  }
}

@mixin header-variant($color, $font) {
  color: $h3-color;
  font-family: $font-primary;
  font-weight: bold;
}

h3 {
  &.basic {
    @include header-variant($h3-color, $font-primary);
  }
}

.custom_title {
  font-family: "Montserrat", sans-serif;
  font-weight: 800;
  text-transform: uppercase;
  color: #fff;
  font-size: 4rem;
  text-decoration: none;

  &:hover {
    color: #fff;
  }
}

p {
  font-size: 1.1rem;
  font-family: "Montserrat", sans-serif;
  color: #000;
}

.custom_error {
  font-size: 0.8rem;
  color: #ff4136;
  margin: 0;
}

.custom_header {
  position: fixed;
  z-index: 99;
  background-color: #10306c;
  height: $header-height-mobile;
  transition: 0.4s;
  width: 100%;
  &.shrinked_header {
    height: $header-height-mobile;
  }

  @media (min-width: map-get($grid-breakpoints, "md")) {
    height: $header-height-desktop;
  }
}

.img_header {
  width: auto !important;
  min-height: 100%;
  max-height: 100%;
  object-fit: contain;
  transition: 0.4s;
}


.custom_subTitle {
  font-family: "Helvetica Neue", sans-serif;
  color: #fff;
  font-size: 1rem;
  text-align: center;
  line-height: 15px;
}

.header-animate-icon {
  opacity: 1;
  font-size: 1.25rem;

  transition: .4s;

  &.hide {
    opacity: 0;
    font-size: 0;
  }
}

.custom_link {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 1.1rem;
  cursor: pointer;
  padding: 0 1rem 0 1rem;
  margin: 0;
  height: 100%;
  transition: .3s;


  &.small {
    font-size: 1rem;
  }


  & div {
    position: relative;

    &::before {
      content: '';
      position: absolute;
      bottom: 0;
      right: 0;

      height: .125rem;
      width: 0;
      background-color: map-get($theme-colors, "light");
      transition: .3s;
    }
  }

  &:hover {
    color: map-get($theme-colors, "light");

    div::before {
      bottom: 0;
      right: 0;
      width: 100%;
    }
  }

  &.sidebar_link {
    color: $dark-blue;
    padding: 1rem;
    margin: 0;
    justify-content: start;

    &:hover {
      color: black;
      transition: .3s all ease-in-out;
    }
  }
}

.link_active div {
  box-shadow: inset 0 -.125rem map-get($theme-colors, "light") !important;
}

.custom_card_title {
  transform: scale(1);
  transition: 0.3s;

  p {
    text-align: center;
    font-size: 1.1rem;
    font-family: "Montserrat", sans-serif;
    font-weight: bold;
  }

  a {
    text-decoration: none;
    color: #000;
  }

  &:hover {
    transform: scale(0.98);
  }
}

.custom_bg {
  background-image: url("../src/assets/landing-background.png");
  // background-image: url("../src/assets/landing-background_NEW.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  min-height: calc(100vh - #{$header-height-mobile});

  @media(min-width: map-get($grid-breakpoints, "lg")) {
    min-height: calc(100vh - #{$header-height-desktop});
  }
}

.custom_bg_home {
  background-image: url("../src/assets/jimmy-dean-landing_v2.png");
  background-repeat: no-repeat;
  background-size: 100%;
  background-attachment: fixed;
}

.custom_bg_marketplace {
  min-height: calc(100vh - #{$header-height-mobile});
  padding-top: 4rem;

  background-image: url("../src/assets/images/mp-bg.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;


  @media(min-width: map-get($grid-breakpoints, "md")) {
    min-height: calc(100vh - #{$header-height-desktop});
  }

  &--big_nav {
    @media(min-width: map-get($grid-breakpoints, "lg")) {
      min-height: calc(100vh - #{$header-height-desktop});
    }
  }

}

.new_home_bg {
  position: relative;
  background-image: url("../src/assets/2nd_hp_bg.jpg");
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: bottom;
  background-color: #96b9cf;

}

.home_bg {
  position: absolute;
  width: 100%;
  // height: 100%;
  top: 0;
  left: 0;
  background-image: url("../src/assets/jimmy-dean-landing_v2_mask.png");
  background-repeat: no-repeat;
  background-size: cover;
  min-height: calc(100vh - #{$header-height-mobile});

  @media(min-width: map-get($grid-breakpoints, "lg")) {
    min-height: initial;
    height: 100%;
    background-size: 100%;

    // min-height: calc(100vh - #{$header-height-desktop});
    // background-size: 100%;
  }
}

.marketplaceSearchTransition {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  transition: 2s;
  transition-timing-function: linear;
  background-color: #ffffff80;
}

.marketplaceSearchTransition.off {
  top: 0;
  transform: translateX(-50%) translateY(100%);
  background-color: #ffffff00;
}

.mapTransition {
  max-height: 0px;
}

.mapTransition.off {
  max-height: 3000px;
}

.mapTransition.disableAnim {
  max-height: none !important;
}

.custom_login_wrapper {
  padding: 2rem;
  background-color: rgba(255, 255, 255, 0.5);
  //  border-radius: 29px;
}

.verticalDivider {
  padding-top: 20px;
  border: none;
  width: 1px;
  margin: 0 .5rem 0 .5rem;
  background: black;
  opacity: .5;
  // border-top: 1px solid black;
}

.paddingX0 {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.wNav {
  text-decoration: none;
}

.activeNav {
  font-weight: bold;
  color: #10306c;
}

.darkerGray.darkerGray-secondary {
  background: $darker-gray;
  border: none;
}

.darkerGray {
  background: $darker-gray;
}

.lightGray {
  background: #F8F8F8 !important;
}

.lightBlue {
  background: #9fc0ff;
}

.darkBlue {
  background: #93c2db;
}

.shadow {
  box-shadow: 0px 10px 5px #000;
}

.white {
  background: #fff;
}

.colorWhite {
  color: #fff;
}
.colorWhiteHover:hover{
  color: #fff;
}


.icon-medium {
  box-sizing: content-box;
  height: 50px;
  width: auto;
}


.width-1-1 {
  width: 100%;
}

.height-1-1 {
  height: 100%;
}

.height-1-2 {
  height: 50%;
}

.transparent-border {
  border: solid transparent 3px !important;
}

.marketplaceMarker {
  background: #9FC0FF;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  transform: translate(-50%, -50%);
}

.resize-none {
  resize: none;
}

[id].acc {
  display: none;
}

[id].acc:checked+label {
  background: map-get($theme-colors, success);
  color: #fff;
}

[id].acc-variant-primary,
[id].acc-variant-success {
  display: none;
}

[id].acc-variant-primary:checked+label {
  background: map-get($theme-colors, primary);
  color: #fff;
}

[id].acc-variant-primary#Occupied:checked+label {
  background: map-get($theme-colors, danger);
  color: #fff;
}

[id].acc-variant-primary#Vacant:checked+label {
  background: map-get($theme-colors, success);
  color: #fff;
}

[id].acc-variant-success:checked+label {
  background: map-get($theme-colors, success);
  color: #fff;
}

.labelColor {
  font-size: 20px;
  font-weight: 600;
  color: $dark-blue;
}

.darkBlue {
  background: $dark-blue;
}

.prev-blur {
  transition: 0.2s;
}

.d-blurred {
  filter: blur(2px);
  opacity: .4
}

// Import Bootstrap and its default variables
@import "~bootstrap/scss/bootstrap.scss";


// Link decorations
.linkStyle {
  text-decoration: none;
  color: #000;

  &:hover {
    color: #000;
  }
}

.nav-link {
  color: #000;
}

.nav-link:hover,
.nav-link:focus {
  color: #000;
}

// Apartment info 
.apartmentInfoNav {
  text-decoration: none;
  background: $darker-gray;
  display: inline-block;
  color: #000;

  .nav-link.active {
    color: map-get($theme-colors, "light") !important;
    background-color: map-get($theme-colors, "primary") !important;

  }
}

.jumpToItem {
  list-style-type: none;
  width: 100%;
  height: 100%;
  background: $darker-gray;

  &--active {
    background: #747474;
  }

}

.jumpToLink {
  text-decoration: none;
  color: #000;

  &:hover {
    color: #000;
  }
}

.squareBoxUnit {
  width: 100px !important;
  height: 100px !important;
}

.lightBlue {
  background: #9fc0ff;
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
  color: #fff;
  background-color: #747474;
}

.nav-link:hover,
.nav-link:focus {
  color: #000;
}

.textLightBlue {
  color: #9FC0FF
}

.textGray {
  color: #A3AFC4;
  border-left: 2px solid #D8D8D9;
  border-right: 2px solid #D8D8D9;
  opacity: 1;
}

.textDarkGray {
  color: #625151;
}

.textLightBlack {
  color: #4A4A4A;
}

.bgDarkTextWhite {
  background-color: #747474;
  color: #fff;
}

.rounded-lg {
  @media (min-width: 992px) {
    border-radius: 5px;
  }
}

.resFontSize {
  font-size: .8rem;

  @media (min-width: 576px) {
    font-size: 1rem;
  }

  @media (min-width: 768px) {
    font-size: 1rem;
  }

  @media (min-width: 992px) {
    font-size: 1.2rem;
  }
}

.montserrat {
  font-family: "Montserrat", sans-serif;
}

// CUSTOM CHECKBOX
.checkboxContainer {
  display: block;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:checked~.customCheckmark:after {
      display: block;
    }
  }

  .customCheckmark:after {
    content: "X";

    font-weight: 500;
    font-size: 16px;
    color: #000;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
}

.customCheckmark {
  position: absolute;
  top: 50%;
  left: 0;

  transform: translateY(-50%);

  height: 24px;
  width: 24px;
  background-color: #FFF;
  border: 1px solid black;
  border-radius: 0.25rem;

  &:after {
    content: "";
    position: absolute;
    display: none;
  }
}

.customSelect {
  -webkit-appearance: none;
  -moz-appearance: none;
  // background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-image: url("data:image/svg+xml,<svg width='24' height='24' xmlns='http://www.w3.org/2000/svg'><path d='m0,6l12,12l12,-12l-24,0z'/><path fill='none' d='m0,0l24,0l0,24l-24,0l0,-24z'/></svg>");
  background-repeat: no-repeat;
  // background-position-y: 60%;
}

.marketplaceMarkerPoint {
  background: #10306c;
  color: #fff;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}


.marketplaceMarkerCluster {
  background: #9fc0ff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.darkerBlue {
  background: #10306c;
}

@media (min-width: 768px) {
  .w-md-50 {
    width: 50% !important;
  }
}

.veryDarkGray {
  background: #707070;
}

.on-hover .show-on-hover {
  display: none;
  white-space: nowrap;
}

.on-hover:hover .show-on-hover {
  display: inline;
  z-index: 1000;
}

.on-hover:hover {
  z-index: 1001;
  transition: .2s background ease-in-out;
  // background: #10306c !important;

  div {
    z-index: 1001;
    transition: .2s background ease-in-out;
    // background: #10306c !important;
  }
}

.marker-highlight {

  transition: .2s all ease-in-out;

  &--active {
    z-index: 1001;
    scale: 1.1;
  }

}

.cursor-pointer {
  cursor: pointer;
  user-select: none;
}

.modal-fullHeight {
  position: fixed;
  top: 0;
  left: 0;

  margin: 0 auto;
  padding: 0;
  height: 100vh;
  min-width: 80vw;
  overflow: hidden;
}

.on-hover-bg-primary:hover {
  transition: .2s background ease-in-out;
  background: #10306c !important;
  color: map-get($theme-colors, "light");
}

.responsive-w-70 {
  width: 90%;

  @media screen and (min-width: 768px) {
    width: 70%;
  }
}


// Side Drawer

.sideDrawer {
  position: fixed;
  top: 0;
  // overflow-y: auto;

  height: 100vh;
  width: 100%;
  padding: 0;

  color: black;
  background: white;

  z-index: 10000;


  transition: .3s transform ease-in-out;

  &--right {
    right: 0;
    transform: translateX(100%);
  }

  &--left {
    left: 0;
    transform: translateX(-100%);
  }

  &--show {
    transform: translateX(0);
  }

  & .nav-close-icon {
    font-size: 1rem;

    @media (min-width: map-get($grid-breakpoints, "md")) {
      font-size: 1.6rem;
    }
  }

  @media (min-width: 400px) {
    width: 380px;
  }
}

.sideDrawer__close {
  display: flex;
  justify-content: flex-end;

  margin-bottom: 38px;
}

// Backdrop
.backdrop {
  width: 100%;
  height: 100%;

  position: fixed;
  z-index: 998;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(2px);
}

// Skeleton loader
.skeleton {
  animation: skeleton-loading 1s linear infinite alternate;
}

@keyframes skeleton-loading {
  0% {
    background-color: hsl(0, 0%, 85%);
  }

  100% {
    background-color: hsl(0, 0%, 95%);
  }
}

.inner-shadow-primary {
  box-shadow: inset 0 0 4px 2px #10306c;
}

.fs-7 {
  font-size: 0.85rem !important;
}

.imageHover {
  transition: .15s;
}

.imageHover:hover {
  transform: scale(0.98);
}

.disableLink {
  pointer-events: none;
  color: $darker-gray;
}

.bg-white-opaque {
  background: rgba(255, 255, 255, 0.9);
}

.rounded-hp {
  border-radius: 29px;
}

.bg-light-blue {
  background: $light-blue;
}

.bg-lighter-blue {
  background: $lighter-blue;
}

.bg-dark-purple {
  background: $dark-purple;
}

.bg-light-purple {
  background: $light-purple;
}

.font-helvetica {
  font-family: 'Helvetica Neue', sans-serif;
}

.home-desc {
  color: $dark-purple;
  font-weight: bold;
  font-size: 1rem;

  @media (min-width: map-get($grid-breakpoints, "sm")) {
    font-size: 1.1rem;
  }
}

.home-btn {
  font-family: 'Helvetica', 'Montserrat', sans-serif;
  font-weight: bold;
  font-size: 1rem;

  @media (min-width: map-get($grid-breakpoints, "sm")) {
    font-size: 1.25rem;
  }
}

.bg-before {
  position: absolute;
  top: .8rem;
  left: -.2rem;
  width: 100%;
  height: 100%;
  z-index: -1;

  background: $light-blue;
  border-radius: 29px;
  opacity: 0.9;

  &.bg-light-purple {
    background: $light-purple;
  }
}

.btn-purple {
  background: $light-purple;

  &:hover {
    background: scale-color($color: $light-purple, $lightness: -30%);
  }
}

.btn-lighter-blue {
  background: $lighter-blue;

  &:hover {
    background: scale-color($color: $lighter-blue, $lightness: -10%);
  }
}

.full-height {
  min-height: calc(100vh - #{$header-height-mobile});

  @media(min-width: get-map($grid-breakpoints, "md")) {
    min-height: calc(100vh - #{$header-height-desktop});
  }
}


.blured-section {
  width: 100%;
  height: 100%;

  position: absolute;
  z-index: 1000;
  left: 0;
  top: 0;
  // background-color: rgba(0,0,0,0.05);
  backdrop-filter: blur(2px);
}

.custom_heading {
  font-size: $font-size-base * 2;
  font-family: "Montserrat", sans-serif;
  font-weight: 800;
  color: #10306c;

  @media (min-width: 576px) {
    font-size: $font-size-base * 2.5;
  }
}

.custom_p {
  color: #fff;
  font-size: 1.25rem;
}

.menu-icon {
  color: #fff;
  font-size: 2.5rem;

  cursor: pointer;
}

.marketplace__search-bg {
  background: rgba(255, 255, 255, 0.5);
  // background: transparent;
  // border-radius: 29px;

  @media(min-width: map-get($grid-breakpoints, "md")) {
    // background: transparent;
  }
}

.loader__logo,
.loader__logo:before,
.loader__logo:after {
  border-radius: 50%;
  width: 2.5em;
  height: 2.5em;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: load7 1.8s infinite ease-in-out;
  animation: load7 1.8s infinite ease-in-out;
}

.loader__logo {
  color: #10306c;
  font-size: 10px;
  margin: 80px auto;
  position: relative;
  text-indent: -9999em;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;

  &--light {
    color: white;
  }
}

.loader__logo:before,
.loader__logo:after {
  content: '';
  position: absolute;
  top: 0;
}

.loader__logo:before {
  left: -3.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.loader__logo:after {
  left: 3.5em;
}

@-webkit-keyframes load7 {

  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }

  40% {
    box-shadow: 0 2.5em 0 0;
  }
}

@keyframes load7 {

  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }

  40% {
    box-shadow: 0 2.5em 0 0;
  }
}

.on-hover-bold {
  font-weight: normal;
  transition: all .1s ease-in-out;

  &:hover {
    font-weight: bold;
  }

}

.border-right-green {
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;

    height: 100%;
    width: .3rem;
    background-color: map-get($theme-colors, "success");
  }

  &:hover::before {
    width: .5rem;
    transition: .3s;
  }
}

.border-right-blue {
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;

    height: 100%;
    width: .3rem;
    background-color: map-get($theme-colors, "primary");
  }

  &:hover::before {
    width: .5rem;
    transition: .3s;
  }
}

.border-right-red {
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;

    height: 100%;
    width: .3rem;
    background-color: map-get($theme-colors, "danger");
  }

  &:hover::before {
    width: .5rem;
    transition: .3s;
  }
}

.border-right-secondary {
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;

    height: 100%;
    width: .3rem;
    background-color: map-get($theme-colors, "secondary");
  }

  &:hover::before {
    width: .5rem;
    transition: .3s;
  }
}

.border-x {
  border-left: 3px solid $darker-gray;
  border-right: 3px solid $darker-gray;
}

.border-x-light-gray {
  border-left: 3px solid $light-gray;
  border-right: 3px solid $light-gray;
}

.border-down-green {
  box-shadow: inset 0 -.25rem map-get($theme-colors, "success"), 0 0.125rem 0.25rem rgba(0, 0, 0, 0.08) !important;
  // border-bottom: .25rem map-get($theme-colors, "success") !;
}

.inbox-border-inside-y {
  box-shadow: none;

  @media (min-width: map-get($grid-breakpoints, "xl")) {
    box-shadow: inset 2px 0 white, inset -2px 0 white;
  }
}

.inbox-element-h {
  height: calc(100vh - 4.375rem - 1rem - #{$header-height-mobile});

  @media (min-width: map-get($grid-breakpoints, "xl")) {
    height: calc(100vh - 2rem - #{$header-height-mobile});
  }
}

.features-spacing {
  margin: 0 0 0 -0.5rem !important;
}

.dropdown {
  position: absolute;
  z-index: 1;
  top: calc(#{$header-height-desktop});
  right: 0;
  width: 300px;
  // transform: translate(-45%);
  overflow: hidden;
  transition: .4s;

  &.shrinked {
    top: calc(#{$header-height-mobile});
  }

  .menu-item {
    height: 50px;
    display: flex;
    align-items: center;
  }
}

.header-initials {
  width: 2.6rem;
  height: 2.6rem;
  font-size: 1.4rem;
  font-weight: 900;
  border-radius: 1rem;
  transition: 0.3s;

  &.big {
    width: 5rem;
    height: 5rem;
    font-size: 2rem;
    border-radius: 1.8125rem;
  }
}

.team-photo {
  object-fit: cover;
  // height: auto;
  // width: auto;
  transition: 0.3s;

  &:hover {
    transform: scale(0.98);
  }
}

// .rc-slider-step {

//     &:first-child {
//       opacity: 0;
//     }

// }

.rc-slider-dot {

  &:first-child,
  &:last-child {
    opacity: 0;
  }
}

.modal-1200px {
  max-width: 1300px;
  width: 100%
}

.marketplace-unit-card {
  transition: .3s;

  &:hover {
    background: $btn-color !important;
    color: map-get($theme-colors, "light") !important;
  }
}

.on-hover-underline {
  position: relative;
  transition: .3s;

  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;

    height: 1px;
    width: 100%;
    background-color: map-get($theme-colors, "secondary");
    opacity: 0;
    transition: .3s;
  }

  &:hover::before {
    opacity: 1;
  }
}

.property-box-hover {
  transition: .3s;

  &:hover {
    background: $darker-gray;
  }
}

.on-hover-scale {
  transform: scale(1);
  transition: .3s;

  &:hover {
    transform: scale(0.98);
  }
}

.scrollIndicator {
  animation: 1s scrollIndicator infinite alternate ease-in-out;
  transform: translateY(-50%) translateX(50%);
}

@keyframes scrollIndicator {
  from {
    transform: translateY(-40%) translateX(50%);
  }

  to {
    transform: translateY(-60%) translateX(50%);
  }
}

.scrollToTop {
  transition: 1s;
  bottom: 0;
  border-radius: 18px;
  z-index: 9999;
}

.scrollToTop.off {
  bottom: -200px;
}

.scale-smaller {
  transform: scale(0.98);
}

.multiCarousel__slider {
  --items-per-screen: 5;
  --slider-index: 0;
  display: flex;
  flex-grow: 1;
  margin: 0 .25rem;
  transform: translateX(calc(var(--slider-index) * -100%));
  transition: transform .3s ease-in-out;
}

.multiCarousel__slider>.multiCarousel__imgContainer {
  flex: 0 0 calc(100% / var(--items-per-screen));
  max-width: calc(100% / var(--items-per-screen));
  aspect-ratio: 16 / 9;
  padding: 0 .25rem;
  border-radius: 1rem;
}

.multiCarousel__handle {
  border: none;
  border-radius: .25rem;
  flex-grow: 0;
  flex-shrink: 0;
  background-color: $darker-gray;
  z-index: 10;
  margin: .25rem 0;
  width: 3rem;
  cursor: pointer;
  font-size: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  line-height: 0;
  transition: background-color 150ms ease-in-out;

  &:hover {
    background-color: map-get($theme-colors, "primary");;
  }

  &>i {
    transition: transform 150ms ease-in-out;
  }

  &:hover i {
    transform: scale(1.2);
  }
}

.multiCarousel__handle-left {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.multiCarousel__handle-right {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

@media (max-width: 1000px) {
  .multiCarousel__slider {
    --items-per-screen: 3;
  }
}

@media (max-width: 500px) {
  .multiCarousel__slider {
    --items-per-screen: 2;
  }
}

.floating-action-button {
  transition: 1s;
}

.floating-action-button.toTop {
  transform: translateY(-100px) translateX(-50%) !important;
}

.floating-action-button.opened {}

.floating-action-button.hide {
  transform: translateY(200px) translateX(-50%) !important;
  pointer-events: none;
}

.fabMain {
  box-shadow: 0 0 5px 2px rgba(255,255,255,0.3);
}

.floating-action-button .fabMain {
  transition: .2s;
  border-radius: 18px;
}

.floating-action-button.opened .fabMain {
  transform: rotate(90deg)
}

.floating-action-button .fabBtn {
  transform: translateY(30px);
  opacity: 0;
  transition: .2s;
  border-radius: 18px;
}

.floating-action-button.opened .fabBtn {
  transform: translateY(0px);
  opacity: 1;
}

.floating-action-button .fabBtn>p {
  transform: translate(5px, -50%);
  opacity: 0;
  // max-width: 0;
  transition: transform .1s, opacity .1s;
}

.floating-action-button.opened .fabBtn>p {
  transform: translate(0px, -50%);
  opacity: 1;
  // max-width: 200%;
}

.floating-action-button .fabBG {
  background: #ffffff00;
  // TODO cant be 170 here, must be responsive
  width: calc(100% + 20px + 170px);
  height: calc(100% - 30px);
  border-radius: 18px;
  right: -10px;
  top: -10px;
}

.floating-action-button.opened .fabBG {
  background: #fffffff5;
}

.grayToHover {
  filter: grayscale(.95);
  transition: .5s;
}

.grayToHover:hover {
  filter: grayscale(0);
}

.trans-box-bg {
  height: 100%;
  width: 100%;
  position: absolute;
  left: -1rem;
  bottom: -0.8rem;
  opacity: .9;
  border-radius: 29px;
  z-index: -1;
}

.advantages-box{
  box-sizing: border-box;
  width: 100%;
}

.advantages-box>div {
  margin: 1.5rem;
}

.thumbnail-video {
  background: none;
  border: none;
}

.thumbnail-video-header {
  border: none;
}

.thumbnail-video-header>.btn-close {
  color: #fff;
}

.thumbnail-video-cta {
  color: #fff;
  vertical-align: middle;
  text-align: center;
  transform: translate(-50%) rotate(90deg);
}

.thumbnail-video-cta path {
  stroke-width: 30px;
  stroke-dasharray: 1000px;
  stroke-dashoffset: 1000px;
  stroke-linecap: round;

}

.thumbnail-video-cta .ctaFill path {
  stroke-width: 55px;
  // animation: thumbnailCtaStroke1 3s infinite alternate-reverse ease-in-out;
  // stroke: $light-blue;
  stroke: #fff;
  stroke-dasharray: 1000px;
  stroke-dashoffset: 000px;
  fill: $lighter-blue;
}

.thumbnail-video-cta .ctaFill {
  z-index: -1;
}

.thumbnail-video-cta .ctaStroke:nth-child(1) path {
  animation: thumbnailCtaStroke1 3s infinite alternate-reverse ease-in-out;
  stroke: $light-blue;
}

.thumbnail-video-cta .ctaStroke:nth-child(2) path {
  animation: thumbnailCtaStroke2 3s infinite alternate-reverse ease-in-out;
  stroke: $light-purple;
}

@keyframes thumbnailCtaStroke1 {
  0% {
    stroke-dashoffset: -880px;
  }

  50%,
  100% {
    stroke-dashoffset: 0px;
  }
}

@keyframes thumbnailCtaStroke2 {
  0% {
    stroke-dashoffset: -830px;
  }

  65%,
  100% {
    stroke-dashoffset: 0px;
  }
}

.color-light-purple {
  color: $light-purple;
}

.color-light-blue {
  color: $light-blue;
}

.on-hover-bg-secondary {
  transition: .3s;

  &:hover {
    background: #707070 !important;
    color: hsl(0, 0%, 95%);
  }
}

.w-16-px {
  width: 16px;
  height: 16px;
}


.marketplace-search {
  background: #ffffffec;
}

.marketplace-search.searched {
  background: #ffffff00;
}

.bi-bg-circle {
  --size: 1.75rem;
  display: inline-block;
  border-radius: 50%;
  text-align: center;
  vertical-align: middle;
  width: var(--size);
  height: var(--size);
  line-height: var(--size);

  &.size-2 {
    --size: 2rem;
  }
}

.fade-in-out {
  animation: 1s fade-in-out infinite ease-in-out alternate-reverse;
}

@keyframes fade-in-out {
  from {
    opacity: 1;
  }

  to {
    opacity: .6;
  }
}

.h-100-minusNav {
  height: calc(100vh - var(--header-height-mobile)) !important;

  @media (min-width: map-get($grid-breakpoints, "md")) {
    height: calc(100vh - var(--header-height-desktop)) !important;
  }
}

.custom-padding-marketplace {
  padding: 8px;

  @media (min-width: map-get($grid-breakpoints, "sm")) {
    padding: 36px;
  }
}

.border-inline-yellow {
  box-shadow: inset 0 0 0px 3px map-get($theme-colors, "warning");
}

.border-inline-dark {
  box-shadow: inset 0 0 0px 1px map-get($theme-colors, "dark");
}

.marketplace-legend {
  &--mobile {
    display: block;

    @media (min-width: 1445px) {
      display: none;
    }

  }

  &--desktop {
    display: none;

    @media (min-width: 1445px) {
      display: block;
    }

  }
}

.disable-outline {
  box-shadow: none !important;
}

.opacity-transition {
  opacity: 0;
  transition: .3s opacity ease-in-out;

  &--show {
    opacity: 1;
  }
}

.translate-end {
  transform: translateX(-100%);
}

.translate-start {
  transform: translateX(0%);
}

.translate-top {
  transform: translateY(100%);
}

.translate-bottom {
  transform: translateY(0%);
}

.translate-bottom-right {
  transform: translate(-100%, -100%);
}

.translate-bottom-left {
  transform: translate(0%, -100%);
}

.translate-top-right {
  transform: translate(-100%, 0%);
}

.translate-top-left {
  transform: translate(0%, 0%);
}

.pathReg {
  opacity: .15;
  transition: .15s;
}

.pathReg.active {
  opacity: 1
}

.userTypeInit {
  opacity: 0;
  transform: scale(.8);
  transition: 1s;
}

.userTypeInit.done {
  transform: scale(1);
}

.userTypeNone>.userTypeInit.done {
  opacity: .3;
  transition: transform .15s, opacity 1s;
}

.userType>.userTypeInit.done {
  opacity: 1;
  transition: transform .15s, opacity 1s;
}

.userType img {
  transition: .15s;
  cursor: pointer;
}

.marketplace-side-menu-tab {

  transition: .3s transform ease-in-out;

  &--animate {
    transform: translateX(-100%);
  }

}

.animation-transition {
  transition: .3s ease-in-out;
}

.marketplace-title-anim span {
  opacity: 0;
  animation-duration: 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.marketplace-title-anim span:nth-child(1) {
  animation-name: appearAnimation;
  animation-delay: .4s;
}

.marketplace-title-anim span:nth-child(2) {
  animation-name: appearAnimation;
  animation-delay: .8s;
}

.marketplace-title-anim span:nth-child(3) {
  animation-name: appearAnimation;
  animation-delay: 1.2s;
}

.marketplace-title-anim span:nth-child(4) {
  animation-name: appearAnimation;
  animation-delay: 1.6s;
}

@keyframes appearAnimation {
  100% {
    opacity: 1;
  };
}

.userType:hover img {
  transform: scale(1.1);
}

.userMapFadeIn {
  opacity: 0;
  animation-name: mapFadeIn;
  animation-duration: 1s;
  animation-delay: 1.2s;
  animation-fill-mode: forwards;
}

.colorWhiteHover:hover{
  color: #fff;
}

@keyframes mapFadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.opacity-0 {
  opacity: 0;
}

.marketplace-title {
  font-size: 4rem;
  font-weight: 700;
}

.marketplace-title>span {
  opacity: 0;
  animation: marketplaceTitleLetter 2s linear forwards;
}

@keyframes marketplaceTitleLetter {
  from {
    opacity: 0;
  }to{
    opacity: 1;
  }
}

@media screen and (max-width: 768px) {
  .marketplace-title {
    padding-top: 2rem;
    font-size: 2rem;
  }
}

@media screen and (max-height: 720px) {
  .marketplace-title {
    font-size: 2rem;
  }
}

@media screen and (max-width: 770px){
  .marketplace-title {
    font-size: 2rem;
    padding-top: 2rem;
    // margin: 2rem 0;
  }
}

@media screen and (max-width: 767px){
  .marketplace-title {
    display: none;
  }
}
@media screen and (max-height: 630px){
  .marketplace-title {
    display: none;
  }
}
.noStreetView{
  width: 160px;
  background: #ebebeb;
}
.noStreetView text{
  font: 10px $font-primary ;
  fill: #a0a0a0;
  // translate: 50% -50%;
}

.noStreetViewSVG{
  font-family: $font-primary;
}

.bar{
  background: #6200EE;
  filter: drop-shadow(0 2px 5px gray) !important;
}
.bar i{
  font-size: 1em;
}
.bar p{
  font-size: .7em;
}
.bar *{
  color: #fff;
  text-align: center;
}

.chip{
  background: #ddd;
  border-radius: 20px;
  padding: 5px 20px;
  position: relative;

  & img{
    width: 25px;
    background: #eee;
    border-radius: 20px;
    padding: 4px;
    margin-right: 10px;
  }

  &--disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
}

.border-wissp-light-gray {
  border-color: rgba(112, 112, 112, .1) !important;
}

.text-wissp-violet {
 color: #6200EE;
}

.wissp-background-violet {
  background: #6200EE !important;
 }
.backdrop-loader{
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 9999;
  left: 0;
  top: 0;

  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);
  background-color: rgba(0, 0, 0, 0.3);
}

.me-17 {
  margin-right: 17px !important;
}

.overflow-scroll-y {
  overflow-y: scroll;
}
.overflow-scroll-x {
  overflow-x: scroll;
}

.wissp-scroll-box,
.table-responsive {
  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  &::-webkit-scrollbar-track {
    background: $darker-gray;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: rgb(16, 48, 108); 
    border-radius: 10px;
  }
}

.client-bar--padding {
  margin-right: 38px !important;
}

.wissp-roboto {
  font-family: 'Roboto', sans-serif;
}

.left-scrollbar {
  direction: rtl;

  & *{
    direction: ltr;
  }
}

.wissp-btn-light-violet {
  border-color: #6200EE !important;
  color: rgb(98, 0, 238) !important;
  background: rgba(98, 0, 238, 0.1) !important;
}

.wissp-accordion-header .accordion-button {
  background-color: $darker-gray !important;
  box-shadow: none;
  padding: 8px;

  @media(min-width: map-get($grid-breakpoints, "md")) {
    padding: 16px;
  }
}

.wissp-accordion-header .accordion-button.collapsed {
  background-color: white !important;
}

.command-center {
  &__drop-area {
    @extend .darkerGray;
    border: 2px map-get($theme-colors, "dark") dashed;
    height: 200px !important;
    width: 100%;

    transition: all .3s ease-in-out;

    &--active {
      border: 2px map-get($theme-colors, "success") dashed;
      transform: scale(1.02);

      & p {
        color: map-get($theme-colors, "success");
      }
    }
  }
}

.timepicker-ui-hour,
.timepicker-ui-minutes,
.timepicker-ui-clock-face {
  background: $light-gray !important;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, .08);
}
.timepicker-ui-hour.active,
.timepicker-ui-minutes.active {
  background: $darker-gray !important;
  
  color: map-get($theme-colors, "success") !important;
  border-color: map-get($theme-colors, "success") !important;
}
.timepicker-ui-hour:hover,
.timepicker-ui-minutes:hover {
  color: map-get($theme-colors, "success") !important;
}
.timepicker-ui-type-mode {
  background: $light-gray !important;
}
.timepicker-ui-type-mode:hover {
  border-color: map-get($theme-colors, "success") !important;
  color: map-get($theme-colors, "success") !important;
}
.timepicker-ui-type-mode.active {
  background: $darker-gray !important;
  color: map-get($theme-colors, "success") !important;
}
.timepicker-ui-circle-hand,
.timepicker-ui-dot,
.timepicker-ui-clock-hand {
  background: map-get($theme-colors, "success") !important;
}
// .timepicker-ui-cancel-btn {
//   color: black !important;
// }
// .timepicker-ui-ok-btn {
//   color: black !important;
// }

.outline-none:focus {
  outline: none;
}
.me-17 {
  margin-right: 17px !important;
}
.rotate180{
  transition: 250ms;
  rotate: 0deg;
}
.rotate180.rotate{
  rotate: 180deg;
}

.text-primary{
  color: map-get($theme-colors, "light");
}
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type="number"] {
    -moz-appearance: textfield;
}
.header-navigation-desktop {
  display: none;

  @media (min-width: 1400px) {
    display: block;
  }
}

.table {
  &-header {
    &__wrapper {
      padding: 0 0 4px 0 !important;
      cursor: pointer;
    }
    &__text {
      white-space: nowrap;
      font-weight: 700;
      font-size: 1rem;
      text-transform: uppercase;
      margin-bottom: 0;
      &-icon {
        font-size: .7rem;
        display: inline-flex;
      }
    }
    &__icon {
      display: flex;
      font-size: .7rem;
    }
  }
  &-body {
    &__wrapper {
      padding: 0 0 0 0 !important;
    }
    &__text {
      font-size: 0.85rem;
      margin-bottom: 0;
      color: inherit;
    }
    &__more-groups-popup {
      opacity: 0;
      cursor: auto;
      z-index: -1;
      transition: opacity .3s ease-in-out;
    }
    &__group-wrapper:hover &__more-groups-popup {
      opacity: 1;
      cursor: auto;
      z-index: 1;
    }
  }
}

.time-picker {
  &__time-select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    // outline: ;
    overflow-y: auto;
  }

  &__time-input:focus,
  &__time-input:active {
    outline: none;
    background: rgba(0,0,0,0.5);
    color: white;
  }
  &__time-input--error {
    outline: solid map-get($theme-colors, "danger" ) !important;
  }
}

.form-control--time-picker[readonly] {
  background-color: white !important;
}

$custom-toggle-height: 1.6em;

.custom-toggle{
  .react-toggle-track{
    background: map-get($theme-colors, "danger");
    border-radius: .3em;
    height: $custom-toggle-height;
  }
  .react-toggle-thumb{
    box-sizing: border-box;
    background-color: #fff;
    border: 3px solid map-get($theme-colors, "danger");
    top: 0;
    bottom: 0;
    // scale: .95;
    border-radius: .3em;
    height: $custom-toggle-height;
    width: 1em;
  }
}
.custom-toggle.custom-toggle.custom-toggle:hover{
  .react-toggle-track{
    background: map-get($theme-colors, "danger");
  }
}
.custom-toggle.react-toggle--checked{
  .react-toggle-thumb{
    border: 3px solid map-get($theme-colors, "success");
    left: 33px;
  }
  .react-toggle-track{
    background: map-get($theme-colors, "success");
  }
}
.custom-toggle.custom-toggle.custom-toggle.react-toggle--checked:hover{
  .react-toggle-track{
    background: map-get($theme-colors, "success");
  }
}

.border-left-green{
  border-left: 8px solid map-get($theme-colors, "success");
}
.border-left-red{
  border-left: 8px solid map-get($theme-colors, "danger");
}
.fav-button{
  position: relative;
  clip-path: polygon(-420% 0px, 100% 0%, 100% 100%, -420% 100%);
}
.fav-button div{
  position: absolute;
  left: 0;
  width: 300px;
  transition: .2s;
  clip-path: polygon(0 0, 0 0, 0 100%, 0 100%);
}
.fav-button:hover div{
  left: -420%;
  clip-path: polygon(90% 0px, 0% 0%, 0% 100%, 90% 100%);
}
.fav-button i{
  position: relative;
  z-index: 3;
}

.fs {
  &-7{
    font-size: 0.85rem;
  }
  &-8{
    font-size: 0.7rem;
  }
}

.team {
  background: #fff;
  overflow: hidden;
  display: block;
  width: 100%;

  & .buildingCircle {
    fill: #ee9090;
    cursor: pointer;
    transition: fill .3s ease-in-out;
  }

  & .bigCircle {
    fill: #1a68ff;
    cursor: pointer;
    transition: fill .3s ease-in-out;
  }

  & .smallCircle {
    fill: #91ec91;
    cursor: pointer;
    transition: fill .3s ease-in-out;
  }

  & .prevBuildingCircle {
    fill: #ffcc99;
    cursor: pointer;
    transition: fill .3s ease-in-out;
  }

  .nodeGroup.inactive circle {
    fill: #b8b8b8;
  }

  & .links {
    stroke: #b8b8b8;
    stroke-width: 1px;
    fill: none;
    transition: all .3s ease-in-out;
    &.inactive {
      stroke: #b8b8b8;
      fill: none;
      stroke-width: 1px;
    }
    &.small.active {
      stroke: #5de65d;
      fill: none;
      stroke-width: 4px;
    }
    &.big.active {
      stroke: #5de65d;
      fill: none;
      stroke-width: 4px;
    }
  }

  & .circleText {
    font-weight: bold;
    // fill: white;
    font-family: Montserrat, sans-serif;
    text-anchor: middle;
  }

  & .bigCircleText {
    // font-size: 52px;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
    cursor: pointer;
  }

  & .smallCircleText {
    // font-size: 32px;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
    cursor: pointer;
  }

  &__menu-row-hover-indicator {
    width: 0;
    transition: width .1s ease-in-out;
  }
  &__menu-row:hover .team__menu-row-hover-indicator {
    width: 6px;
  }
  &__more-button {
    outline: none;
    border: none;
    padding:0 16px;
    width: 100%;
    font-size: 12px;
    background: transparent;
    font-weight: bold;
  }

  &__menu-row-action-box {
    max-height: 0;
    overflow: hidden;
    // transition: max-height 0.2s ease-out;

    &--active {
      max-height: 100%;
    }
  }

  &__graph-cover-loader {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    // z-index: 1;
    background: white;
    opacity: 0;

    &.active {
      z-index: 1;
      // opacity: 1;
      animation: animationGraphFade .5s;
    }
  }
}

@keyframes animationGraphFade {
  0% {
    // z-index: 1;
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    // z-index: 1;
  }
  // 100% {
    // z-index: -1;
  // }
}

.import-units {
  &__download-box {
    transition: 0.3s all ease-in-out;
    border: 1px dashed rgba(0, 0, 0, 0.25);

    

    &:hover,
    &--drag {
      border: 1px dashed $btn-border-basic;
      background: white;
    }
  }
}

.bg-light-yellow {
  background-color: #FEEFC1 !important;
  background: #FEEFC1 !important;
}

.import-added {
  opacity: 0.6;
  cursor: not-allowed;
}

.import-cell-error {
  background: #ffb0ac !important;
  background-color: #ffb0ac !important;
}